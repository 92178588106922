.container__failed {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  padding: 0px 30px;
  background: #ddd;
}

.wrapper {
  max-width: 960px;
  width: 100%;
  margin: 30px auto;
  transform: scale(0.8);
}

.landing-page {
  max-width: 960px;
  height: 475px;
  margin: 0;
  box-shadow: 0px 0px 8px 1px #ccc;
  background: #fafafa;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.failed__heading {
  text-align: center;
  font-size: 40px;
  margin: 0;
}

.failed__text {
  font-size: 30px;
  width: auto;
  margin: 16px auto 24px;
  text-align: center;
}

.try-again__button {
  border-radius: 50px;
  padding: 8px 24px;
  font-size: 24px;
  cursor: pointer;
  background: #007ead;
  color: #fff;
  border: none;
  box-shadow: 0 4px 8px 0 #ccc;
}