* {
  padding: 0px;
  margin: 0px;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  position: absolute;
  margin: 0 auto;
}

.error {
  &-page {
    color: #16a085;
    margin: auto;
  }

  &-text {
    font-size: 50px;
    font-family: 'FootlightMTLight';
  }
}

.message {
  color: #16a085;

  &:after {
    content: ']';
  }

  &:before {
    content: '[';
  }

  &:after,
  &:before {
    color: #16a085;
    font-size: 20px;
    animation-name: opacity;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    margin: 0 15px;
    -webkit-animation-name: opacity;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
  }
}

@-webkit-keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}