$x-space: 24px;
$y-space: 16px;

.css-1aquho2-MuiTabs-indicator {
  background-color: #fff !important;
}

.card-front {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  padding-bottom: 15px;
  z-index: 100;

  &__image {
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }

  &__number {
    position: absolute;
    font-size: 26px;
    top: 35%;
    left: $x-space;
    color: #000;
  }

  &__chip {
    position: absolute;
    top: $y-space;
    left: $x-space;
    height: 44px;
  }

  &__symbol {
    position: absolute;
    top: $y-space;
    right: $x-space;
    height: 48px;
  }

  &__info {
    position: absolute;
    bottom: $y-space;
    right: $x-space;
    color: white;
    text-align: left;
    margin: 0;

    &.left {
      left: $x-space;
    }

    .value {
      font-weight: bold;
    }

    p {
      margin: 0;
    }
  }

  @media screen and (max-width: 480px) {
    &__number {
      font-size: 22px;
    }

    &__info {
      font-size: 12px;
    }

    &__chip {
      height: 34px;
    }

    &__symbol {
      height: 38px;
    }
  }

  @media screen and (max-width: 360px) {
    &__number {
      font-size: 18px;
    }
  }
}

.card-form {
  margin: 0 auto;
}

.card-form {
  max-width: 570px;
  margin: auto;
  width: 100%;

  &__inner {
    background: #fff;
    box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__row {
    display: flex;
    align-items: flex-start;
  }

  &__col {
    flex: auto;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__group {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .card-input__input {
      flex: 1;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__button {
    width: 100%;
    height: 55px;
    background: #38a294;
    border: none;
    border-radius: 5px;
    font-size: 22px;
    font-weight: 500;
    box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
    color: #fff;
    margin-top: 20px;
    cursor: pointer;
  }
}

.disabled {
  filter: grayscale(80%);
  background: grey;
  cursor: default;
}

.card-input {
  margin-bottom: 20px;

  &__label {
    margin-bottom: 5px;
    color: #1a3b5d;
    width: 100%;
    display: block;
    text-align: left;
  }

  &__input {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid #ced6e0;
    transition: all 0.3s ease-in-out;
    font-size: 18px;
    padding: 5px 15px;
    background: none;
    color: #1a3b5d;

    &:hover,
    &:focus {
      border-color: #38a294;
    }

    &:focus {
      box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
    }
  }
}

.credit-card {
  font-family: "Source Code Pro", monospace;
  max-width: 420px;
  width: 100%;
  height: 245px;
  background-color: transparent;
  color: white;
  margin-bottom: 20px;
  perspective: 1000px;
  display: inline-block;

  &__inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }

  @media screen and (max-width: 480px) {
    height: 210px;
  }

  @media screen and (max-width: 360px) {
    height: 180px;
  }
}